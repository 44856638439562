import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  dailySidebarShow: false,
  sidebarUnfoldable: false,
  cloudModelSelection: [],
  localModelSelection: [],
  isDigitalTwin: false,
  lastblock: [{ code: '' }],
  theme: 'light',
  configToColor: [{
    value: 0,
  }],
  selectedElement: null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
