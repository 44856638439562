export const dashTranslations = {
  spanish: {
    dashboard: {
      kpis: {
        title: "KPI's",
        items: [
          {
            alias: 'Avance físico real'
          },
          {
            alias: 'Avance programado'
          },
          {
            alias: 'Días de atraso'
          },
          {
            alias: 'Óptimos en atraso'
          },
          {
            alias: 'Simples en atraso'
          }
        ]
      },
      chart: {
        title: 'Avance general de obra',
      },
      specialityAdv: {
        title: 'Avance por especialidad'
      }
    }
  },
  english: {
    dashboard: {
      kpis: {
        title: "KPI's",
        items: [
          {
            alias: 'Real physical progress'
          },
          {
            alias: 'Scheduled progress'
          },
          {
            alias: 'Delayed days'
          },
          {
            alias: 'Delayed critical blocks'
          },
          {
            alias: 'Delayed simple blocks'
          }
        ]
      },
      chart: {
        title: 'Work general progress',
      },
      specialityAdv: {
        title: 'Advancement by specialty'
      }
    }
  }
}

export const dtTranslations = {
  spanish: {
    visibility: {
      action: 'Estado',
      onClose: [
        'Cerrar',
        'Seleccionar'
      ]
    },
    modelSelection: {
      action: 'Nube',
      title: 'Seleccione el modelo que desea ver',
      buttons: [
        'Descargar',
        'Aplicar'
      ]
    },
    localModelSelection: {
      action: 'Local',
      title: 'Seleccione el modelo que desea ver',
      buttons: [
        'Cargar',
        'Aplicar'
      ],
      placeholder: 'Cargá un modelo local'
    },
  },
  english: {
    visibility: {
      action: 'Status',
      onClose: [
        'Close',
        'Select'
      ]
    },
    modelSelection: {
      action: 'Cloud',
      title: 'Select the model you want to see',
      buttons: [
        'Download',
        'Apply',
      ]
    },
    localModelSelection: {
      action: 'Local',
      title: 'Select the model you want to see',
      buttons: [
        'Load',
        'Apply'
      ],
      placeholder: 'Load a local model'
    },
  }
}

export const blockModalTranslations = {
  spanish: {
    title: [
      'Bloque',
      'Descripción'
    ],
    table: {
      item_1: 'Fecha programada',
      item_2: 'Fecha de reprogramación',
      item_3: 'Fecha de ejecución',
      item_4: 'Atraso en días',
      item_5: 'Ejecución física',
      item_6: 'GUID'
    }
  },
  english: {
    title: [
      'Block',
      'Description'
    ],
    table: {
      item_1: 'Scheduled date',
      item_2: 'Rescheduled date',
      item_3: 'Executed date',
      item_4: 'Delay days',
      item_5: 'Physical execution',
      item_6: 'GUID'
    }
  }
}

export const worktableTranslations = {
  spanish: {
    export: {
      action: 'Exportar tabla',
    }
  },
  english: {
    export: {
      action: 'Export table',
    }
  }
}